export default {
  "scavenger-hunt": "scavengerHunt",
  "what-came-first": "whatCameFirst",
  "find-me": "findMe",
  "merge-faces": "mergeFaces",
  "make-words": "makeWords",
  "the-pouring-game": "thePouringGame",
  "doodle-match": "doodleMatch",
  "field-master": "fieldMaster",
  "bat-master": "batMaster",
  "hexa-drop": "hexaDrop",
  "lyric-lingo": "lyricLingo",
  "falling-blocks": "fallingBlocks",
  memorize: "memorize",
  whacko: "whacko",
  scavengerHunt: "scavenger-hunt",
  jigsaw: "jigsaw",
  "true-false": "trueFalse",
  trueFalse: "true-false",
  gtm: "gtm",
  whatCameFirst: "what-came-first",
  findMe: "find-me",
  mergeFaces: "merge-faces",
  makeWords: "make-words",
  thePouringGame: "the-pouring-game",
  doodleMatch: "doodle-match",
  fieldMaster: "field-master",
  batMaster: "bat-master",
  hexaDrop: "hexa-drop",
  lyricLingo: "lyric-lingo",
  quizzical: "quizzical",
  fallingBlocks: "falling-blocks",
  gameComponents: {
    scavengerHunt: "ScavengerHunt",
    jigsaw: "Jigsaw",
    trueFalse: "TrueOrFalse",
    gtm: "GTM",
    whatCameFirst: "WhatCameFirst",
    findMe: "FindMe",
    mergeFaces: "MergeFaces",
    memorize: "Memorize",
    whacko: "Whacko",
    makeWords: "MakeWords",
    thePouringGame: "ThePouringGame",
    doodleMatch: "DoodleMatch",
    fieldMaster: "FieldMaster",
    batMaster: "BatMaster",
    hexaDrop: "HexaDrop",
    lyricLingo: "lyricLingo",
    quizzical: "Quizzical",
    fallingBlocks: "fallingBlocks"
  }
};
