<template>
  <div>
    <div
      v-if="started && gameData.game_details"
      class="wrapper bg-black grid content-start w-full items-start pt-10 place-items-center"
    >
      <div
        class="font-ZuumeSemiBold text-5xl text-nColorOrange uppercase flex relative"
      >
        <p class="relative">
          <span>
            <span>What cam</span>
            <div class="inline-block w-4">
              <p class="rotated-alphabet absolute -top-4 ml-0.5">e</p>
            </div>
            <span>&nbsp; First</span>
          </span>
        </p>
      </div>
      <Counter class="mt-4" :score="score" :displayTime="displayTime" />
      <div class="flex gap-2 mt-4">
        <div
          class="wcf-container grid place-items-center content-start pt-4 relative"
          v-if="gameData.game_details.whatcamefirsts[currentSetIndex]"
        >
          <div
            :class="{ 'disable-click': isClickDisabled }"
            class="w-full grid place-items-center relative"
            @click="selectOption('option1')"
          >
            <img
              :src="
                gameData.game_details.whatcamefirsts[currentSetIndex]
                  .option1_image
              "
              class="w-8/12 border border-black rounded-lg image-shadow-right"
            />
            <div
              id="option1Overlay"
              class="absolute top-0 left-0 bg-black bg-opacity-70 w-full h-full hidden"
            >
              <div class="flex w-full h-full items-center content-center">
                <lottie-animation
                  id="option1Correct"
                  path="lotties/answer-correct.json"
                  :loop="true"
                  :autoPlay="true"
                  :speed="1"
                  :height="60"
                  class="hidden"
                />
                <lottie-animation
                  id="option1Wrong"
                  path="lotties/answer-wrong.json"
                  :loop="true"
                  :autoPlay="true"
                  :speed="1"
                  :height="60"
                  class="hidden"
                />
              </div>
            </div>
            <div
              id="option1Text"
              class="absolute -bottom-4 left-1/2 transform -translate-x-1/2 bg-nColorYellow border border-black px-2 py-0.5 text-black font-ZuumeSemiBold text-2xl rounded-lg text-center"
            >
              {{
                gameData.game_details.whatcamefirsts[currentSetIndex].option1
              }}
            </div>
          </div>
          <div class="text-nColorYellow font-ZuumeSemiBold text-3xl py-6">
            OR
          </div>
          <div
            :class="{ 'disable-click': isClickDisabled }"
            class="w-full grid place-items-center relative"
            @click="selectOption('option2')"
            v-if="gameData.game_details.whatcamefirsts[currentSetIndex]"
          >
            <img
              :src="
                gameData.game_details.whatcamefirsts[currentSetIndex]
                  .option2_image
              "
              class="w-8/12 border border-black rounded-lg image-shadow-left"
            />
            <div
              id="option2Overlay"
              class="absolute top-0 left-0 bg-black bg-opacity-70 w-full h-full hidden"
            >
              <div class="flex w-full h-full items-center content-center">
                <lottie-animation
                  id="option2Correct"
                  path="lotties/answer-correct.json"
                  :loop="true"
                  :autoPlay="true"
                  :speed="1"
                  :height="60"
                  class="hidden"
                />
                <lottie-animation
                  id="option2Wrong"
                  path="lotties/answer-wrong.json"
                  :loop="true"
                  :autoPlay="true"
                  :speed="1"
                  :height="60"
                  class="hidden"
                />
              </div>
            </div>
            <div
              id="option2Text"
              class="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-nColorYellow border border-black px-2 py-0.5 text-black font-ZuumeSemiBold text-2xl rounded-lg text-center"
            >
              {{
                gameData.game_details.whatcamefirsts[currentSetIndex].option2
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Counter from "@/components/elements/ScoreCounter.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";

export default {
  name: "WhatCameFirst",
  props: {
    gameData: {
      type: Object,
      required: true,
    },
  },
  components: { Counter, LottieAnimation },
  data() {
    return {
      started: false,
      totalIndex: 0,
      initialTime: {
        sec: 30,
        minute: 1,
        milliseconds: 0,
      },
      displayTime: {
        sec: 30,
        minute: 1,
        milliseconds: 0,
      },
      answerStartTime: {
        sec: 30,
        minute: 1,
        milliseconds: 0,
      },
      totalTimeInMilliseconds: 90 * 1000,
      currentSetIndex: 0,
      score: 0,
      totalTimeTaken: 0,
      userAnswers: [],
      isClickDisabled: false,
    };
  },
  created() {
    this.totalIndex = this.gameData.game_details.whatcamefirsts.length;
    this.startGame();
  },
  mounted() {},
  methods: {
    startGame() {
      this.started = true;
      this.countDown();
    },
    selectOption(option) {
      this.isClickDisabled = true;
      let answer = {
        set: this.gameData.game_details.whatcamefirsts[this.currentSetIndex]
          .slug,
        answer: option,
        time_taken: 0,
      };
      document.querySelector("#option1Overlay").classList.remove("hidden");
      document.querySelector("#option2Overlay").classList.remove("hidden");
      document.querySelector("#option1Text").innerHTML =
        this.gameData.game_details.whatcamefirsts[
          this.currentSetIndex
        ].option1_year;
      document.querySelector("#option2Text").innerHTML =
        this.gameData.game_details.whatcamefirsts[
          this.currentSetIndex
        ].option2_year;
      if (
        this.gameData.game_details.whatcamefirsts[this.currentSetIndex]
          .correct_answer === option
      ) {
        answer.is_correct = true;
        this.score += 1;
        document
          .querySelector("#" + option + "Correct")
          .classList.remove("hidden");
        const timeTaken = this.calculateTimeTakenForAnswer();
        this.totalTimeTaken += timeTaken;
        answer.time_taken = timeTaken;
      } else {
        answer.is_correct = false;
        document
          .querySelector("#" + option + "Wrong")
          .classList.remove("hidden");
        const timeTaken = this.calculateTimeTakenForAnswer();
        answer.time_taken = timeTaken;
      }
      this.userAnswers.push(answer);
      if (this.currentSetIndex + 1 === this.totalIndex) {
        this.submitScore();
      }
      setTimeout(() => {
        this.currentSetIndex += 1;
        document.querySelector("#option1Overlay").classList.add("hidden");
        document.querySelector("#option2Overlay").classList.add("hidden");
        document.querySelector("#option1Correct").classList.add("hidden");
        document.querySelector("#option1Wrong").classList.add("hidden");
        document.querySelector("#option2Correct").classList.add("hidden");
        document.querySelector("#option2Wrong").classList.add("hidden");
        this.isClickDisabled = false;
      }, 1000);
    },
    countDown() {
      this.timer = setInterval(() => {
        if (
          this.displayTime.minute == 0 &&
          this.displayTime.sec == 0 &&
          this.displayTime.milliseconds == 0
        ) {
          clearInterval(this.timer);
          this.submitScore();
        } else {
          if (this.displayTime.milliseconds == 0) {
            if (this.displayTime.sec == 0) {
              this.displayTime.sec = 59;
              this.displayTime.minute--;
            } else {
              this.displayTime.sec--;
            }
            this.displayTime.milliseconds = 99; // Reset milliseconds to 99
          } else {
            this.displayTime.milliseconds--;
          }
        }
      }, 10);
    },
    submitScore() {
      ApiService.post(apiResource.saveWhatCameFirstScore, {
        business_slug: process.env.VUE_APP_PLAYDA_DEMO_SLUG,
        time: this.totalTimeTaken.toString(),
        score: this.score.toString(),
        answers: this.userAnswers,
        game_session_id: this.gameData.game_session_id,
      }).then(() => {
        const url = new URL(window.location.href);
        url.searchParams.set("redirect", "gameover");
        url.searchParams.set("game_session_id", this.gameData.game_session_id);
        window.location.href = url.toString();
      });
    },
    calculateTimeTakenForAnswer() {
      const diffMilliseconds =
        (this.answerStartTime.minute - this.displayTime.minute) * 60000 +
        (this.answerStartTime.sec - this.displayTime.sec) * 1000 +
        (this.answerStartTime.milliseconds - this.displayTime.milliseconds);
      this.answerStartTime = { ...this.displayTime };
      return diffMilliseconds;
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100dvh;
}
.rotated-alphabet {
  display: inline-block;
  transform: rotate(-11.27deg);
  -webkit-transform: rotate(-11.27deg);
  -moz-transform: rotate(-11.27deg);
  -ms-transform: rotate(-11.27deg);
  -o-transform: rotate(-11.27deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.correct-bg {
  background-color: #daf559;
}
.incorrect-bg {
  background-color: #ff74c7;
}
.image-shadow-left {
  box-shadow: 4px 4px 0px rgba(255, 203, 59, 0.8);
}
.image-shadow-right {
  box-shadow: -4px 4px 0px rgba(255, 203, 59, 0.8);
}
.disable-click {
  pointer-events: none;
}
</style>
